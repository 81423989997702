.Slide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.Slider {
    height: 100%;
    position: relative;
} 

.Arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 45px;
    color: white;
    z-index: 1;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black, -1px -1px black, 1px -1px black, -1px 1px black, 1px 1px black;
}

.Arrow:hover {
    color: rgb(218, 201, 255);
}

.Left {
    left: 20px;
}

.Right {
    right: 20px;
}

.Unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DotContainer {
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

.Dot {
    padding: 10px;
    padding-top: 0px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 900;
}