.Footer {
    background-color: rgb(75, 46, 131);
    color: rgb(218, 201, 255);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.ContactUsText {
    font-size: 30px;
    margin-left: auto;
}

.Contacts {
    margin-top: 5px;
}

.Link {
    color: rgb(218, 201, 255);
    text-decoration: underline;
}

.TextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: auto;
}

@media (max-width: 650px) {
    .Footer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Contacts {
        margin-left: 3%;
        margin-right: 3%;      
    }

    .ContactUsText {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .TextContainer {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 30px;
    }
}