.Header {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);  
    color: black; 
    width: 100%;
    height: 70px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px 0 rgb(83, 83, 83);
    z-index: 3;
}

.HomeButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    text-align: left;
    height: 100%;
}

.HeaderImg {
    height: 55px;
    margin-right: 10px;
}

.HeaderText {
    font-size: 40px;
    margin: 5px;
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.Navbar {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 20px;
}

@media (max-width: 900px) {
    .Navbar {
        display: none;
    }
}

.NavItem {
    font-size: 18px;
    margin: 10px;
    color: black;
    text-decoration: none;
    font-weight: 575;
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.NavItem:hover {
    background-color: rgb(218, 201, 255);
    color: rgb(75, 46, 131);
}
