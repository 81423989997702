.Sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SideNavItem {
    font-size: 30px;
    margin: 10px;
    color: black;
    text-decoration: none;
    font-weight: 575;
    padding: 10px;
    margin-right: auto;
    text-wrap: nowrap;
}

.ThreeBars {
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    display: none;
    font-size: 27px;
}

@media (max-width: 900px) {
    .ThreeBars {
        display: block;
    }
}

.Unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Close {
    padding: 10px;
    font-size: 50px;
    margin-left: auto;
    margin-top: -13px;
    margin-right: 6px;
    cursor: pointer;
}

.Dim {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    display: none;
    opacity: 75%;
    transition: 0.5s;
}